.App {
  height: 100%;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  text-align: center;
  color:white;
}

.router-link {
  text-decoration: none;
  color: initial;
}
.router-link:visited {
  text-decoration: none;
  color: initial;
}

.status-chip-connectedToNode {
  background-color: #4ac94c !important;
  color: white !important;
}

.status-chip-offline {
  background-color: #c65872 !important;
  color: white !important;
}

.pipeline-chip-READY {
  background-color: #4ac94c !important;
  color: white !important;
}

.pipeline-chip-ERROR {
  background-color: #c65872 !important;
  color: white !important;
}
